import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { prefixLng } from '../util';
import FacebookProvider from '../components/FacebookProvider';
import { isIE } from 'react-device-detect';
import BaseController from '../components/BaseController';
import cartBackground from '../assets/images/for-all-page/shop_icon.svg';
import fbBackground from '../assets/images/for-all-page/fb-iframe-icon.svg';
import phoneBackground from '../assets/images/for-all-page/phone_icon.svg';

export default withTranslation('HelpBox')(
    class HelpBox extends BaseController {
        constructor(props) {
            super(props);

            this.state = {
                fixedPosition: false,
                currentLanguage: props.i18n.language,
                isShowFacebookProvider: false
            };

            this.showFacebookProvider = this.showFacebookProvider.bind(this);
        }

        showFacebookProvider() {
            this.setState(this.toggleFacebookState(this.state));
        }

        toggleFacebookState(state) {
            return {
                isShowFacebookProvider: !state.isShowFacebookProvider
            };
        }

        checkPopUp() {
            var whatsappUrl = 'https://wa.me/85234605498';
            window.open(whatsappUrl, '_blank');
        }

        componentDidMount() {
            window.addEventListener('scroll', this.scrollEvent);
            this.showFacebookProvider();
        }

        componentWillUnmount() {
            window.removeEventListener('scroll', this.scrollEvent);
        }

        render() {
            const { t } = this.props;
            var { isShowFacebookProvider } = this.state;

            const currentLanguage = this.props.i18n.language === 'en' ? ' lang-en' : '';

            return (
                <div className="help-conainer">
                    <a href="https://shop.souvenaid.com.hk" target="_blank" rel="noopener">
                        <div className="shopping-cart">
                            <div className="button-background">
                                <img src={cartBackground} />
                            </div>
                            <span className={'white' + currentLanguage}>{t('ONLINE_SHOP')}</span>
                        </div>
                    </a>
                    {/* <a href="https://wa.me/85234605498" onClick={this.checkPopUp} className="d-md-none d-block">
                        <div className="call-us">
                            <span className={'white' + currentLanguage}>{t('HOTLINE')}</span>
                        </div>
                    </a> */}
                    <a href="https://wa.me/85234605498" className="breadcrumb__link d-md-block d-none" target="_blank" rel="noopener">
                        <div className="call-us">
                            <div className="button-background">
                                <img src={phoneBackground} />
                            </div>
                            <span className={'white' + currentLanguage}>{t('HOTLINE')}</span>
                        </div>
                    </a>

                    <a href="https://www.facebook.com/SouvenaidHK/" target="_blank" rel="noopener">
                        <div
                            className="fb-page-btn position-relative"
                            onMouseEnter={this.handleMouseHover}
                            onMouseLeave={this.handleMouseHover}>
                            <div className="button-background">
                                <img src={fbBackground} />
                            </div>
                            <span className={'white' + currentLanguage}>{t('FACEBOOK_PAGE')}</span>
                            {isShowFacebookProvider && (
                                <div className={isIE ? 'fb-iframe isIE' : 'fb-iframe'}>
                                    <FacebookProvider />
                                </div>
                            )}
                        </div>
                    </a>
                </div>
            );
        }
    }
);
